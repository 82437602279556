import React from "react";

class MyComponent extends React.Component {
    constructor() {
      super();
      this.state = {
        responseData: null,
      };
    }
  
    handleClick = () => {
      // Send the API request here
      fetch('https://c2f86a9mog.execute-api.us-east-1.amazonaws.com/dev')
        .then((response) => response.json())
        .then((data) => {
          // Update the state with the response data
          this.setState({ responseData: data });
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };
  
    render() {
      return (
        <div>
          <button onClick={this.handleClick}>Fetch Data</button>
          {this.state.responseData && (
            <div>
              <h2>API Response:</h2>
              <pre>{JSON.stringify(this.state.responseData, null, 2)}</pre>
            </div>
          )}
        </div>
      );
    }
  }
  
  export default MyComponent;
  