import React from "react";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import Project from "../components/ProjectDisplay/Project/Project";
import Spacer from "../components/Spacer/spacer";

const projects = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col w-full min-h-screen">
        <Spacer />
        <Project
          img={"WebComission01.png"}
          title={"Commission Website"}
          description={
            "This is a commission project I completed using React and hosted using AWS."
          }
          linkPath="/projects/comissionwebsite"
        />
        <Spacer />
      </div>
      <Footer />
    </>
  );
};

export default projects;
