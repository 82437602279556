import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import IndexAbout from "./components/Index/IndexAbout/IndexAbout";
import ProjectDisplay from "./components/ProjectDisplay/ProjectDisplay";
import Spacer from "./components/Spacer/spacer";
import ContactForm from "./components/ContactForm/contactForm";
import TestButton from "./components/testButton";

/*
<div className="app-container">
      <Spacer />
      <div className="index-header">Welcome to my Portfolio!</div>
      <Spacer />
      <IndexAbout />
      <Spacer />
      <ProjectDisplay />
      <Spacer />
      <ContactForm />
      <Spacer />
    </div>
*/

export default function App() {
  return (
    <div className="flex flex-col justify-between min-w-screen min-h-screen bg-slate-700 ">
      <Spacer />
      <IndexAbout />
      <Spacer />
      <ContactForm />
      <Spacer />
    </div>
  );
}
