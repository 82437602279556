import React from 'react'
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'

const comissionwebsite = () => {
  return (
    <>
      <Header />
      <div className=''>
        <p className='comissionwebsite-heading'>Comission Website</p>
        <p>
          This is a project I was comissioned to work on. 
          I worked as part of a 3 man team, mainly with a designer to develop this product.
          The whole service took approximately 3 months including design and refactoring.
          The website uses React components and AWS Services like S3, Amplify for hosting and DynamoBD,
          to store emails for newsletter signups.
        </p>
      </div>
      <Footer />
    </>
  )
}

export default comissionwebsite