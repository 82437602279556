import React from "react";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import ContactForm from "../components/ContactForm/contactForm";
import Spacer from "../components/Spacer/spacer";

const contact = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col w-full min-h-screen">
        <Spacer />
        <ContactForm />
        <Spacer />
      </div>
      <Footer />
    </>
  );
};

export default contact;
