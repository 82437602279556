import React from "react";

const Project = ({ img, title, description, linkPath }) => {
  return (
    <div className="project-display-project-wrapper">
      <div className="project-display-project-img">
        <img src={img} alt="" />
      </div>
      <div className="project-display-project-title">{title}</div>
      <div className="project-display-project-description">{description}</div>
      <div className="project-display-project-read-more">
        <a href={linkPath}>Read More</a>
      </div>
    </div>
  );
};

export default Project;
