import React from "react";

const skillsShowcase = () => {
  return (
    <div className="flex flex-col w-full justify-center items-center sm:items-center gap-5 pl-5 pr-5">
      <div className="flex flex-row w-auto justify-center">
        <img
          src="/SVGs/c-plusplus.svg"
          alt="C++ Icon"
          className="w-10 h-10 sm:w-16 sm:h-16"
        />
        <p className="w-38 h-full text-white text-lg sm:text-3xl pt-1 sm:pt-4 pl-5">
          Intermediate - 2 Years
        </p>
      </div>
      <div className="flex flex-row w-auto justify-center">
        <img
          src="/SVGs/c-sharp.svg"
          alt="C-Sharp Icon"
          className="w-10 h-10 sm:w-16 sm:h-16"
        />
        <p className="w-38 h-full text-white text-lg sm:text-3xl pt-1 sm:pt-4 pl-5">
          Intermediate - 3 Years
        </p>
      </div>
      <div className="flex flex-row w-auto justify-center pr-5 sm:pr-10">
        <img
          src="/SVGs/unity.svg"
          alt="Unity Game Engine Icon"
          className="w-10 h-10 sm:w-16 sm:h-16"
        />
        <p className="w-38 h-full text-white text-lg sm:text-3xl pt-1 sm:pt-4 pl-5">
          Advanced - 5 Years
        </p>
      </div>
      <p className="w-full text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-center pb-10 pt-10">
        AWS Experience
      </p>
      <p className="w-full h-auto text-white text-lg sm:text-3xl text-center">
        I completed an AWS Cloud Computing module while in University, these are
        the services I have most experience with and routinely use in my
        projects.
      </p>
      <div className="flex flex-row w-full sm:w-2/3">
        <img src="/SVGs/aws-amplify.svg" alt="" className="w-10 h-10" />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          Amplify - Simple Web App Deployment Service from AWS, this very
          portfolio runs on Amplify
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img src="/SVGs/aws-api-gateway.svg" alt="" className="w-10 h-10" />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          API Gateway - I primarily use this service to create REST API's that
          call on lambda functions
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img
          src="/SVGs/aws-certificate-manager.svg"
          alt=""
          className="w-10 h-10"
        />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          Certificate Manager - I use it to create SSL certificates for custom
          domains for myself and clients
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img
          src="/SVGs/aws-cloudfront.svg"
          alt="AWS Cloud Front Icon"
          className="w-10 h-10"
        />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          Cloud Front - Fast and affordable content deployment service which I
          use to host apps for clients
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img
          src="/SVGs/aws-codepipeline.svg"
          alt="AWS Code Pipeline Icon"
          className="w-10 h-10"
        />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          Code Pipeline - Incredibly useful service which I integrate with
          github to automatically deploy my code on commit to other AWS services
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img
          src="/SVGs/aws-dynamodb.svg"
          alt="AWS DynamoDB Icon"
          className="w-10 h-10"
        />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          DynamoDB - Easy no-sql database service I use often for all kinds of
          data storage
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img src="/SVGs/aws-ec2.svg" alt="AWS EC2 Icon" className="w-10 h-10" />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          EC2 - Easy and scalable virtual server deployments, I have used them
          to host cutom network nodes and games servers
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img src="/SVGs/aws-iam.svg" alt="AWS IAM Icon" className="w-10 h-10" />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          IAM - I use IAM to create roles for my API's and make sure they
          operate securely
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img
          src="/SVGs/aws-lambda.svg"
          alt="AWS Lambda Icon"
          className="w-10 h-10"
        />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          Lambda - Serverless code runners, I use this AWS service all of the
          time to separate code tasks out from a single program and run API
          functions
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img
          src="/SVGs/aws-route53.svg"
          alt="AWS Route53 Icon"
          className="w-10 h-10"
        />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          Route53 - I use this to register web domains with AWS for myself and
          clients and manage inbound web traffic
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img src="/SVGs/aws-s3.svg" alt="AWS S3 Icon" className="w-10 h-10" />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          S3 - Easy, flexible and cheap data storage I use for almost all my
          projects
        </p>
      </div>
      <div className="flex flex-row w-full sm:w-2/3">
        <img
          src="/SVGs/aws-ses.svg"
          alt="AWS Simple Email Service Icon"
          className="w-10 h-10"
        />
        <p className="w-full h-full text-white text-lg sm:text-3xl pt-1 pl-5">
          Simple Email Service - I use this service to send out automatic
          emails, like the form on this website! The data is collected and
          emailed to myself
        </p>
      </div>
    </div>
  );
};

export default skillsShowcase;
