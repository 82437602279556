import React from "react";

const IndexAbout = () => {
  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-col md:flex-row w-full md:w-3/4 xl:2/3 h-full items-center gap-5">
        <img
          className="w-36 md:w-52 2xl:w-80 h-auto rounded-full"
          src="profile-small.jpg"
          alt="headshot photo"
        />
        <div className="flex justify-center w-full">
          <p className="w-4/5 lg:w-full xl:w-full text-base md:text-xl lg:text-2xl xl:text-4xl 2xl:text-5xl text-white text-center">
            I'm Kacper, a dedicated programmer driven by a passion for crafting
            exceptional software and complex systems. My education took me to
            the Technological University of Dublin, where I spent three years
            immersing myself in the intricacies of computer science.
          </p>
        </div>
      </div>
    </div>
  );
};

export default IndexAbout;
